#modal-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

.modal {
    float: right;
    width: 100%;
    max-width: 480px;
    position: relative;
    z-index: 2;
    background: var(--card-background);
    overflow-y: auto;
    height: 100vh;
}

.modal-header {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
}

.modal-content {
    padding: var(--wrapper-padding);
    padding-top: 0;
    padding-bottom: 80px;
}

.modal h3 {
    font-size: 22px;
    margin: 40px 0;
    color: #666;
}

.modal .modal-content h3:first-child,
.modal .modal-content > button + h3 {
    margin: 0 0 40px 0;
}

.modal-back-button.icon-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
}

.modal .cart-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #EB0000; 
    transform: rotate(45deg);
    width: 100%;   
    top: 50px; 
    right: -150px;
    gap: 10px;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    z-index: 1;
}