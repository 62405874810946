* /* Form styles */
input,
textarea {
    outline: none;
    border: none;
    caret-color: black;
}
input[readonly] {
    caret-color: transparent;
}
input[type=file] {
    display: none;
}
textarea,
textarea.input {
    height: auto;
}
.input-success {
    background: var(--cta-discount);
}
.input-required {
    background: var(--cta-required);
}
::placeholder {
  color: var(--placeholder-color); 
  transition: all var(--speed);
}
form section {
    padding: 20px;
    border: solid 1px var(--input-border-color);
    border-radius: 15px;
}

/* Linear progress styles */
.linear-progress {
    width: 100%;
    height: 4px;
    background: var(--primary-color);
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.linear-progress::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--primary-color-light);
    animation: progress 2s linear infinite;
}
@keyframes progress {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
}
.avatar {
    border: solid 1px var(--border-color);
}
/* label styles */ 
label {
    position: relative;
}
.label {    
    display: block;    
    color: var(--primary-color);
}
.label::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    border-bottom: 1px solid #999;
    transition: all var(--speed);
}  
.label:focus-within::before {
    width: 100%;
    border-color: var(--primary-color);
}
label > svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

/* input styles */
.basic-input {
    background: var(--input-background-color);
    color: var(--input-text-color);
    padding: 10px;
}
.input {    
    display: block;
    width: 100%;
    border-bottom: solid 1px var(--input-border-color);
    padding: 18px 10px;
    background: var(--input-background-color);
    transition: border-color var(--speed);
    color: var(--input-text-color);
    caret-color: black;
    height: 56px;
}
.input:hover {
    border-color: var(--primary-color);
}
.input:focus {
    border-color: var(--primary-color);
}  
/* small styles */
.label small {
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0;
    transition: all var(--speed) linear;
}
.label .input:focus + small,
.focusable-div:focus + small {
    font-size: 10px;
    left: 0;
    top: -16px;
    opacity: 1;
}
/* Select styles */
.select-container * {
    font-size: 12px
}
.select-dropdown {    
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;  
    background: var(--input-background-color);
    z-index: 2;
    overflow-y: auto;   
    box-shadow: var(--box-shadow);     
}
.select-dropdown.bottom {
   top: auto;
   bottom: 100%; 
}
.select-dropdown.modal-input {
    position: fixed;
    max-width: 300px;
    top: 0;
    z-index: 3;
}
.select-dropdown li {
    padding: 10px;
    border: solid 1px var(--input-border-color);
    border-top: none;
    cursor: pointer;
    background: var(--select-background-color);
    transition: all var(--speed);
    color: var(--input-text-color); 
}
.select-dropdown li .select-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.select-dropdown li:hover {
    background: var(--select-background-color-hover);
}
.select-container input {
    min-height: 63px;
}
.select-container .course-item {
    background: #333;
    color: white;
    font-weight: bold;
    cursor: auto;
}
.select-container .course-item:hover {
    background: #333;
    color: white;
}
.select-container .course-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    background: #eee;    
}
.select-container .course-item,
.select-container .course-details {
    border-left: none;
    border-right: none;
}
.select-container .course-details span {
    display: block;
    width: 50%;
    height: 100%;
    padding: 10px;
}
.select-container .course-details span:first-child {
    background: #fff;
}
.select-container .course-details:hover {
    background: #999;
    color: white;
}
.select-container .course-details:hover span:first-child {
    background: #666;
}

.fin-size-select li {
    padding: 0;
    border: none;
}
.fin-size-select li:hover th {
    background: #999 !important;
    transition: all var(--speed);
}
.fin-size-select li table tr th:nth-child(1) {
    width: 50px;  
    background: #555; 
    color: white;  
}
.fin-size-select li table tr td:nth-child(2),
.fin-size-select li table tr td:nth-child(3) {
    width: 140px;
}
.fin-size-select th {
    border: solid 1px #555;
}
.fin-size-select td {
    border: solid 1px var(--border-color);
}

.mobile-parent {
    gap: 2px;
}
.parent .select-container,
.parent .label,
.mobile-parent .label {
    flex: 1;
    width: 100%;
}

/* Radio styles */
.radio-button-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: none;
    border-radius: 50%;
    background-position: center;
    transition: background 0.5s, box-shadow var(--speed);   
    background-color: none;
    cursor: pointer;
}
.radio-button-container:hover {
    background: 
    var(--icon-button-background-hover) 
    radial-gradient(circle, transparent 1%, var(--icon-button-background-hover) 1%) center/15000%;
}
.radio-button-container:active {
    background-color: var(--icon-button-background);
    background-size: 100%;
    transition: all 0s;
}
.radio-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: solid 2px var(--icon-color);
    background: var(--background);
    border-radius: 50%;
    transition: all var(--speed);
}
.radio-button:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: none;
    border-radius: 50%;
}
.radio-button-container:hover .radio-button,
.radio-button-container:hover .radio-button.checked,
.radio-button-container:hover .radio-button.checked:after,
.radio-button-container:active .radio-button{
    border-color: var(--icon-color-hover);
}

.radio-button-container:active .radio-button:after {
    background: var(--icon-color-hover);
}
.radio-button.checked {
    border-color: var(--icon-color);
}
.radio-button.checked:after {
    background: var(--secondary-color);
}
  
/* placeholder styles */
.input::placeholder {
    color: var(--placeholder-color); 
    transition: all var(--speed);
}
.input:focus::placeholder {
    font-size: 0;
}
.adornment-end {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
}

.adornment-start {
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
}

.user-form {       
    border: solid 1px var(--border-color);   
    width: 100%;
    max-width: 480px;
    border-radius: 15px;
    margin: 40px auto;
    box-shadow: var(--box-shadow);
    overflow: hidden;
}
.user-form h3 {    
    font-size: 32px;
    margin: 0;
    font-weight: 300;
    padding: 20px;
}
.user-form a {
    display: flex;
    align-items: center;
}

.search {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 24px;
    background: var(--input-background-color); 
    height: 48px;
}
.search label {       
    flex-grow: 1;
}
.search input {
    text-indent: 1rem; 
    line-height: 46px;
    flex: 1;
    color: var(--input-text-color);
}

input[type="range"] {
    -webkit-appearance: none;
    height: 7px;
    width: 100%;
    background: var(--input-border-color);
    border-radius: 5px;
    background-image: linear-gradient(var(--icon-color), var(--icon-color-hover));
    background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--icon-color);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
}

input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

.button-sticky {
    position: sticky;
    bottom: 40px;
    z-index: 1;
}

.input.transparent {
    background: rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(255,255,255,0.5);
    color: white;
}
.input.transparent + small {
    color: white;
}
.label.transparent ::placeholder {
    color: white;    
}
.select-dropdown.transparent {
    background: transparent; 
}
.select-dropdown.transparent li {
    background: rgba(0, 0, 0, 0.8); 
    color: orange;
    border-color: #555;
}

.filter-input {
    width: 200px;
}

.icon-button.delete-button {
    position: absolute;
    top: 50%;
    right: 0;    
    transform: translateY(-50%);    
}
form h1 {
    display: flex; 
    align-items: center;
    gap: 10px; 
}
form h1 a {
    font-size: 0;
}

@media ( max-width: 768px ) {   
    .flex-mobile {
      flex-direction: column;
    }
    .filter-input {
        width: 100%;
    }
    
  }
