.stepper-container {
    padding: 0 20px;
    overflow-x: auto;
    margin-bottom: 20px;
}

.stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stepper > div {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.stepper small {
    white-space: nowrap;
    margin-top: 5px;
    font-weight: bold;
    color: #555;
}

.stepper span.line {
    border-bottom: solid 2px #ccc;
    flex: 1;
    position: relative;
    top: -10px;
    right: -7px;
    min-width: 20px;
}

.stepper span.line + svg {
    fill: #ccc;
    font-size: 2rem;
    position: relative;
    top: -10px;
    left: -7px;
    min-width: 32px;
}

.stepper span.step {
    background: #333;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    color: white;
    border: solid 15px white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepper span svg {
    fill: white;
    font-size: 1.2rem;
}

.stepper span.step.active {
    background: var(--primary-color);
}

.stepper span.step.complete {
    background: var(--secondary-color);
}

.stepper .active + small {
   color: var(--primary-color);   
}