footer {     
    /* border-top: solid 1px var(--border-color); */
    text-align: center;    
    color: #999;
    position: relative;    
    background: linear-gradient(to top, #000a1a, #001436);
}
footer .background-panel {
    position: relative;
    z-index: 1;
}
footer .parent > div {
    text-align: left;
}
footer h4 {
    margin: 40px 0;
    text-align: inherit;
}
footer .footer-links {
    display: flex;
    justify-content: space-between;  
    flex-wrap: wrap;  
    gap: 20px;
    margin: 40px 0;
    color: white;
}
.footer-table th,
.footer-table td {
  vertical-align: top;
  width: 50%;
  color: #999;
}
footer td p {
    color: #999;
}
footer .footer-table th {
    text-align: right;  
    color: orange;  
    font-weight: normal;
}
footer li a {
    color: #999;
}
footer li a:hover {
    color: #fff;
}

.partners {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.partners li {
    background-position: 0px 0px;
    background-repeat: no-repeat;
    width: 50px; 
    height: 50px;
    margin: 10px 20px; 
}

@media (max-width: 768px) {       
    footer .parent > div,
    footer .parent > div h3 {        
        text-align: center !important;
    }
    footer .parent > div div {        
        justify-content: center;
    }
    footer .footer-links {        
        margin-top: 40px;
        margin: 40px auto;
    }   
}