/* Button  styles */
.pointer-events-none {
    pointer-events: none;
    opacity: .3;
}
button {
    outline: none;
    border: none;
    transition: all var(--speed);
    cursor: pointer;
    background: none;
    display: flex;
    align-items: center;    
    font-size: 1rem;
}
button[disabled] {
    background: var(--button-disabled-background);
    color: var(--button-disabled-color);
    box-shadow: none;
    cursor: not-allowed;
} 
button[disabled]:hover {
    background: var(--button-disabled-background);
    color: var(--button-disabled-color);
    box-shadow: none;
} 
button[disabled]:hover .icon {
    color: #eee;
    transform: scale(1);
}
.button {
    justify-content: center;
    box-shadow: 
    0px 3px 1px -2px rgb(0 0 0 / 20%), 
    0px 2px 2px 0px rgb(0 0 0 / 14%), 
    0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-position: center;
    transition: background 0.8s, box-shadow var(--speed);
    padding: 9px 40px;
    color: var(--button-text-color);
    background: var(--primary-color);
    outline: none;
    border-radius: 8px;
    white-space: nowrap;
    min-width: 120px;
    margin: 40px auto 20px auto;
}
.button:hover {
    box-shadow: 
    0px 2px 4px -1px rgb(0 0 0 / 20%), 
    0px 4px 5px 0px rgb(0 0 0 / 14%), 
    0px 1px 10px 0px rgb(0 0 0 / 12%); 
    background: var(--primary-color-dark) radial-gradient(circle, transparent 1%, var(--primary-color-dark) 1%) center/15000%;
    color: white;
}
.button:active {
    box-shadow: 
    0px 5px 5px -3px rgb(0 0 0 / 20%), 
    0px 8px 10px 1px rgb(0 0 0 / 14%), 
    0px 3px 14px 2px rgb(0 0 0 / 12%);
    background-color: var(--primary-color);
    background-size: 100%;
    transition: all 0s;
    color: white;
}

.button-secondary {
    background-color: var(--secondary-color-dark);
}
.button-secondary:active {
    background-color: var(--secondary-color-dark);
}
.button-secondary:hover {
    background: var(--secondary-color) radial-gradient(circle, transparent 1%, var(--secondary-color) 1%) center/15000%;
}

.danger-button {
    background-color: var(--cta-red);
}
.danger-button:hover {
    background: var(--cta-red-dark) radial-gradient(circle, transparent 1%, var(--cta-red-dark) 1%) center/15000%;
}
.danger-button:active {    
    background-color: var(--cta-red);
    background-size: 100%;
}
.transparent-button  {
    background: transparent;
    border: solid 1px #555;
    color: orange;
   
    border-radius: 0;
    transition: all var(--speed);
}
.transparent-button:hover  {
    background: transparent;
    border: solid 1px orange;
    color: #ccc;
}

.mobile-button.icon-button,
.mobile-button {    
    display: none;    
}  

.icon-button {
    display: flex;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: var(--icon-button-background);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    align-items: center; 
    min-width: 48px;
}
.icon-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    pointer-events: none;
}  
.icon-button:active::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}
.icon-button:hover {
    background: var(--icon-button-background-hover);
}
.icon-button.arrow:hover {
    background: black;
}
.icon-button:hover .icon {
    transform: scale(1.2);
} 
.icon {
    color: var(--icon-color);
    transition: all var(--speed);
    width: 1em;
    height: 1em;
    fill: currentColor;
    font-size: var(--icon-font-size);
}
.icon-button:hover .icon {
    color: var(--icon-color-hover);
}
.icon-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: var(--foreground);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    font-size: 10px;
    font-weight: bold;
}
.loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

/* Tab styles */
.tabs {
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;
}
.tabs button {
    background-color: var(--card-background);
    color: var(--foreground);  
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: all var(--speed);
    border-bottom: solid 2px transparent;
}
.tabs button:hover {
    background: var(--cta-information);
}  
.tabs button.active {    
    border-bottom: solid 2px var(--primary-color);
}
.tabs + section {
    padding: 20px;
    border: solid 1px var(--input-border-color);
    border-radius: 15px;
}
section .large-header {
    margin-top: 40px;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
}
.pagination a {
    height: 30px; 
    line-height: 30px; 
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination li.disabled a {
    pointer-events: none;
    opacity: 0.4; 
}
.pagination li.active a {
    color: #fff; 
    background: var(--primary-color); 
    border-color: var(--icon-color);
    border-radius: 100%;
    pointer-events: none;
}
.pagination a:hover {
    color: #fff; 
    background: var(--icon-color-hover); 
    border-color: var(--icon-color-hover);
}

.cart-table {
    background: var(--card-background);
    border: solid 1px var(--table-border-color);
}
.cart-table td {
    background: var(--background);
    padding: 20px 10px;
    white-space: nowrap;
}
.cart-table th {
    border: none; 
    background: var(--input-background-color);   
    color: var(--icon-color);
    font-weight: 300;
    padding: 20px 10px;
    white-space: nowrap;
}
.cart-table tr:hover td {
    transition: all var(--speed);
    background: var(--input-background-color);
}

.slide-buttons {
    margin: -80px auto 0 auto;
    position: relative;
    z-index: 2;
    max-width: 600px;
}

.thumbnails {
    display: flex;
    gap: 5px;
}
.thumbnails li {
    width: 75px;
}

a.active {
    color: var(--primary-color);
    background: var(--card-background);
    border-bottom: solid 1px var(--primary-color);
    pointer-events: none;
}
.active .icon {
    color: var(--link-text-color-hover);
}  

/* Article Styles */
article {  
    min-width: calc(100% - 260px);
    background: var(--card-background);
    flex: 1;
}
article form {
    margin: 0 auto;
}

.rating-filter svg {
    transition: all var(--speed);
    cursor: pointer;
}
.rating-filter svg:hover {
    fill: var(--icon-color-hover);
}

.fade-in {
    opacity: 0;
    transform: translateY(40px);
    transition: all var(--speed) ease-in;
}
.fade-in.appear {
    opacity: 1;
    transform: translateY(0);
    overflow: hidden;
}

/* Calendar Styles */
.calendar {
    position: absolute;
    top: 59px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    background: var(--background);  
    box-shadow: var(--box-shadow);  
}
.rdrMonthAndYearWrapper {
    background: var(--tertiary-color);
}
.rdrMonthAndYearWrapper select {
    color: orange !important;
}
.rdrMonthAndYearWrapper select option {
    color: black;
}

.filters {
    display: flex;
    flex-direction: column;
}
.filters h6 {
    margin: 40px 0px 20px;
}

.product-table th {
    vertical-align: initial;
    color: #999;
    text-align: right;
    font-size: 14px;
}
.product-table td {
    vertical-align: initial;
    font-size: 14px;
}
.product-table .icon {
    pointer-events: none;
}

.main-content,
.main-content p,
.main-content li {
    line-height: 1.8em;
    color: var(--paragraph-text-color);
}
.main-content h4 {
    color: var(--icon-color);
    margin: 40px 0 20px 0;
}

@media (max-width: 768px) {   
    .filters {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
    .filters h6 {
        margin: 0 0px 20px;
    }    
    .mobile-button.icon-button,
    .mobile-button {
        display: block;        
    }    
    article,
    .parent > div {
        width: 100%;
        max-width: none;
        /* flex: auto;         */
    }      
    .slide-buttons {
        margin: -40px auto 0 auto;        
    }  
    .stepper-container {
        display: none;
    }
}

@media (max-width: 601px) { 
    .tabs button {       
        padding: 10px;        
        gap: 5px;
        font-size: 12px;
    }
}

@media (max-width: 400px) {   
    .carousel-text {
        display: none;
    }

}
